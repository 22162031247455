// @ts-nocheck
import * as React from "react"
import { graphql } from "gatsby"
import Home from "./Home"
import { FacebookProvider, CustomChat } from "react-facebook"

const IndexPage = () => {
  return (
    <>
      <Home />
      <FacebookProvider appId="1051591116094708" chatSupport>
        <CustomChat pageId="304006256679433" minimized={true} />
      </FacebookProvider>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
